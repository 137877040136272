import { useDetectAdBlock } from "adblock-detect-react";
import type { AppProps } from "next/app";
import Helmet from "next/head";
import Script from "next/script";
import NextNProgress from "nextjs-progressbar";
import { useEffect, useState } from 'react';
import TagManager from "react-gtm-module";
import { useUpdateEffect } from "react-use";
import "tailwindcss/tailwind.css";
import Layout from "../components/Layout";
import { MediaContextProvider, mediaStyle } from "../components/Media";
import { searchEngineDisabledPages } from "../data/serachEngineDisabledPages";
import { API } from '../services/api/types';
import "../styles/globals.css";
import "../styles/pages/article.css";
import Custom400 from "./404";
import Custom500 from "./500";
import { AuthContextProvider } from "../utils/AuthContext";

const MyApp = ({ Component, pageProps, router }: AppProps) => {
  const [indexPageNewsClient, setIndexPageNewsClient] = useState<API.Article[]>([]);

  useEffect(() => {
    // If user do not navigate between index/article clear client news.
    if (!['/', '/nyheter/[slug]/[id]'].includes(router.route)) {
      setIndexPageNewsClient([]);
    }

    if (router.route !== '/') {
      window.scrollTo(0, 0);
    }
  }, [router.asPath])

  useUpdateEffect(() => {
    (window as any)[btoa("video intelligence")]?.closeAll?.();
    (window as any)?.lwhb?.resetPage?.(true);
    (window as any)?.leeadsAdClosePlaceholder?.();
  }, [router.asPath]);

  useEffect(() => {
    // Redirect users trying to access site with www.

    // Initialize tag manager
    if (process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID) {
      console.log("Initializing tag manager");
      TagManager.initialize({
        gtmId: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID,
      });
    }
  }, []);

  const adBlockDetected = useDetectAdBlock();
  const isOnAdblockGuidePage = /\/nyheter\/.*?\/93872/g.exec(router.asPath);
  const searchEngineIsDisabled =
    Boolean(
      searchEngineDisabledPages.find((route) => {
        return Boolean(
          (typeof route === "string" && route === router.asPath) ||
            new RegExp(route).test(router.asPath)
        );
      })
    ) || Boolean(process.env.NEXT_PUBLIC_DISABLE_SEARCH_ENGINES);

  if (pageProps.errorStatus) {
    const isUserError =
      pageProps.errorStatus >= 400 && pageProps.errorStatus < 500;

    return (
      <Layout>
        {isUserError ? (
          <Custom400 status={pageProps.errorStatus} />
        ) : (
          <Custom500
            status={pageProps.errorStatus}
            errorId={pageProps.errorId}
          />
        )}
      </Layout>
    );
  }

  // if (adBlockDetected && !isOnAdblockGuidePage) return <AdBlockPage />;

  return (
    <>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, user-scalable=no"
        ></meta>
        
        <script>{` (function () {
        function r(e) {
          if (!window.frames[e]) {
            if (document.body && document.body.firstChild) {
              var t = document.body;
              var n = document.createElement("iframe");
              n.style.display = "none";
              n.name = e;
              n.title = e;
              t.insertBefore(n, t.firstChild);
            } else {
              setTimeout(function () {
                r(e);
              }, 5);
            }
          }
        }
        function e(n, a, o, c, d) {
          function e(e, t, n, r) {
            if (typeof n !== "function") {
              return;
            }
            if (!window[a]) {
              window[a] = [];
            }
            var i = false;
            if (d) {
              i = d(e, r, n);
            }
            if (!i) {
              window[a].push({
                command: e,
                version: t,
                callback: n,
                parameter: r,
              });
            }
          }
          e.stub = true;
          e.stubVersion = 2;
          function t(r) {
            if (!window[n] || window[n].stub !== true) {
              return;
            }
            if (!r.data) {
              return;
            }
            var i = typeof r.data === "string";
            var e;
            try {
              e = i ? JSON.parse(r.data) : r.data;
            } catch (t) {
              return;
            }
            if (e[o]) {
              var a = e[o];
              window[n](
                a.command,
                a.version,
                function (e, t) {
                  var n = {};
                  n[c] = { returnValue: e, success: t, callId: a.callId };
                  if (r.source) {
                    r.source.postMessage(i ? JSON.stringify(n) : n, "*");
                  }
                },
                a.parameter
              );
            }
          }
          if (typeof window[n] !== "function") {
            window[n] = e;
            if (window.addEventListener) {
              window.addEventListener("message", t, false);
            } else {
              window.attachEvent("onmessage", t);
            }
          }
        }
        e("__tcfapi", "__tcfapiBuffer", "__tcfapiCall", "__tcfapiReturn");
        r("__tcfapiLocator");
        (function (e, t) {
          var n = document.createElement("link");
          n.rel = "preconnect";
          n.as = "script";
          var r = document.createElement("link");
          r.rel = "dns-prefetch";
          r.as = "script";
          var i = document.createElement("script");
          i.id = "spcloader";
          i.type = "text/javascript";
          i["async"] = true;
          i.charset = "utf-8";
          var a =
            "https://sdk.privacy-center.org/" +
            e +
            "/loader.js?target_type=notice&target=" +
            t;
          if (window.didomiConfig && window.didomiConfig.user) {
            var o = window.didomiConfig.user;
            var c = o.country;
            var d = o.region;
            if (c) {
              a = a + "&country=" + c;
              if (d) {
                a = a + "&region=" + d;
              }
            }
          }
          n.href = "https://sdk.privacy-center.org/";
          r.href = "https://sdk.privacy-center.org/";
          i.src = a;
          var s = document.getElementsByTagName("script")[0];
          s.parentNode.insertBefore(n, s);
          s.parentNode.insertBefore(r, s);
          s.parentNode.insertBefore(i, s);
        })("66dabc37-39b6-4ead-84cb-e9528d02fa0e", "wTAtpwMp");
      })();`}</script>

        {/* Leeads/Adnami, topscroll/vertical video */}
        <script
          async
          src="https://macro.adnami.io/macro/spec/adsm.macro.fe51d098-6769-4666-b778-84090a8b72bd.js"
        ></script>
        <script>
          {`var adsmtag = adsmtag || {}; adsmtag.cmd = adsmtag.cmd || [];`}
        </script>

        {/* Google Ad Manager fullpage ad implementation (leeadsTakeoverAdslot).
          Added 2019-10-31 22:00
          Updated 2020-10-15 11:25 */}
        <script async src="/scripts/fullpageAd.js"></script>

        {/* Leeads */}
        <script
          async
          src="https://lwadm.com/lw/pbjs?pid=b33d0cb8-c962-4c95-bc15-938050dd8fa7"
        ></script>
        <script type="text/javascript">{`var lwhb = lwhb || {cmd: []}; `}</script>

        {/* SUNT SPONSORED LINKS API */}
        <script async src="/scripts/sponsoredLink.js"></script>

        {/* Native ad script */}
        <script
          async
          src="https://source.advisible.com/10122709/main.js"
        ></script>

        <meta
          name="robots"
          content={searchEngineIsDisabled ? "noindex" : "index"}
        ></meta>

        <link rel="icon" href="/favicon.ico" />

        <style
          type="text/css"
          dangerouslySetInnerHTML={{ __html: mediaStyle }}
        />
      </Helmet>
      {/* Google Analytics 4 */}
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_4_TRACKING_ID}`}
        strategy="afterInteractive"
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_4_TRACKING_ID}');
        `}
      </Script>
      <MediaContextProvider>
        <AuthContextProvider>
          <NextNProgress color="#77A72C" />
          {router.asPath.startsWith('/_app/article/') ? <Component key={router.asPath} {...pageProps} /> : <Layout>
            <Component key={router.asPath} {...pageProps} indexPageNewsClient={indexPageNewsClient} setIndexPageNewsClient={setIndexPageNewsClient}/>
          </Layout>}
          
        </AuthContextProvider>
      </MediaContextProvider>
    </>
  );
};
export default MyApp;
